import React from 'react';

const Pagination = ({
	totalPosts,
	postsPerPage,
	setCurrentPage,
	currentPage,
}) => {
	let pages = [];
	for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
		pages.push(i);
	}
	return (
		<div className='flex flex-wrap justify-center mt-8 gap-3'>
			{pages.map((page, index) => {
				return (
					<button
						key={index}
						className={`w-[40px] h-[40px] font-bold border rounded-lg cursor-pointer transition-all-0.3s hover:scale-110 hover:bg-black hover:text-white border-[#eee] ${
							page === currentPage
								? 'bg-black text-white'
								: 'bg-white text-black'
						}`}
						onClick={() => setCurrentPage(page)}
					>
						{page}
					</button>
				);
			})}
		</div>
	);
};

export default Pagination;
