import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useUser, useSupabaseClient } from '@supabase/auth-helpers-react';
import SignUpConfirmation from './components/SignUpConfirmation';
// import Participate from './components/Participate';
import Vote from './components/Vote';
import ThankYou from './components/ThankYou';
import ViewPets from './components/ViewPets';
import VoteSuccess from './components/VoteSuccess';
import MagicLink from './components/MagicLink';
import ViewSinglePet from './components/ViewSinglePet';
// import Test from './components/Test';
function App() {
	const user = useUser();
	const supabase = useSupabaseClient();

	return (
		<Router>
			<Routes>
				<Route
					path='/signupconfirmed'
					element={<SignUpConfirmation />}
				/>
				{/* <Route
					path='/participate'
					element={<Participate user={user} />}
				/> */}
				<Route
					path='/vote'
					element={<Vote user={user} />}
				/>
				<Route
					path='/'
					element={<ViewPets user={user} />}
				/>
				<Route
					path='/thankyou'
					element={<ThankYou user={user} />}
				/>
				<Route
					path='/votesuccess'
					element={<VoteSuccess user={user} />}
				/>
				<Route
					path='/magiclink'
					element={<MagicLink supabase={supabase} />}
				/>
				<Route
					path='/single/:petId'
					element={<ViewSinglePet user={user} />}
				/>
				{/* <Route
					path='/test'
					element={<Test user={user} />}
				/> */}
			</Routes>
		</Router>
	);
}

export default App;
