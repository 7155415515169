import React from 'react';

const SignUpConfirmation = () => {
	return (
		<section className='max-w-[1140px] h-screen m-auto p-2'>
			<div className='h-full w-full flex flex-col gap-4 justify-center items-center '>
				<h1 className='text-center text-lg'>
					Sign Up Successful.
					<br></br> Please check email for further instructions.
				</h1>
			</div>
		</section>
	);
};

export default SignUpConfirmation;
