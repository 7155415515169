import React, { useState, useEffect, useRef } from 'react';
import supabase from '../supabase';
import { useNavigate } from 'react-router-dom';
import Pagination from './Pagination';
import hero from '../assets/hero.png';
import fire from '../assets/icons8-fire.gif';
import arrow from '../assets/arrow.svg';

const Vote = ({ user }) => {
	// const welcomeMessage = user
	// 	? `Welcome ${user.user_metadata?.firstName}, please select your favorite pet and submit your vote`
	// 	: '';
	const welcomeMessage =
		'Voting has ended. The winner of the 2023 William Mattar Pet Photo Contest will be announced on November 30th!';
	const [pets, setPets] = useState([]);
	const [selectedPetId, setSelectedPetId] = useState(null);
	const navigate = useNavigate();
	const [lazyLoadIndex, setLazyLoadIndex] = useState(6);

	const [loadingState, setLoadingState] = useState({});
	const [searchQuery, setSearchQuery] = useState('');
	const [searchTriggered, setSearchTriggered] = useState(false);

	const [currPage, setCurrPage] = useState(1);
	const [postsPerPage, setPostsPerPage] = useState(16);
	const [filteredPets, setFilteredPets] = useState([]);
	const lastPostIndex = currPage * postsPerPage;
	const firstPostIndex = lastPostIndex - postsPerPage;
	const currentPets = pets.slice(firstPostIndex, lastPostIndex);

	const containerRef = useRef(null);
	const localStorage = window.localStorage;

	const checkLazyLoad = () => {
		const container = containerRef.current;
		if (container) {
			const containerRect = container.getBoundingClientRect();
			if (containerRect.bottom < window.innerHeight * 2) {
				setLazyLoadIndex((prevIndex) => prevIndex + 6); // Load more images when container is within 2x viewport height
			}
		}
	};

	const fetchPetData = async () => {
		try {
			const { data: petsData, error } = await supabase
				.from('pets')
				.select('id, pet_name, owner_name, image_url, votes, status')
				.eq('status', true) // Fetch only pets with status set to true
				.order('votes', { ascending: false });
			if (error) {
				console.error('Error fetching pet data:', error);
				return [];
			} else {
				setPets(petsData);
			}
		} catch (error) {
			console.error('Error fetching pet data:', error);
		}
	};

	const fetchPetsBySearchQuery = async (query) => {
		try {
			const { data, error } = await supabase
				.from('pets')
				.select('id, pet_name, image_url, votes, status')
				.eq('status', true)
				.order('votes', { ascending: false })
				.then((response) => response);

			if (error) {
				console.error('Error fetching pet data:', error);
			} else {
				const lowerCaseQuery = query.toLowerCase();
				const filteredPets = data.filter((pet) =>
					pet.pet_name.toLowerCase().includes(lowerCaseQuery)
				);
				setFilteredPets(filteredPets);
				setSearchTriggered(true);
			}
		} catch (error) {
			console.error('Error fetching pet data:', error);
		} finally {
			setSearchQuery('');
		}
	};

	//fetch last selected pet
	const fetchUserPet = async (userId) => {
		try {
			const { data, error } = await supabase
				.from('profiles')
				.select('selected_pet_id')
				.eq('id', user.id);
			if (error) {
				console.error('Error fetching user data:', error);
				return;
			}
			const selectedPetId = data[0]?.selected_pet_id;
			setSelectedPetId(selectedPetId);
		} catch (error) {
			console.error('Error fetching user pet data:', error);
		}
	};

	const handleVote = async (petId, userId) => {
		setLoadingState((prevLoadingState) => ({
			...prevLoadingState,
			[petId]: true,
		}));

		try {
			// Fetch the previously selected pet ID from the user's profile
			const { data: userData, error: userError } = await supabase
				.from('profiles')
				.select('selected_pet_id')
				.eq('id', userId);

			if (userError) {
				console.error('Error fetching user data:', userError);
				return;
			}

			const previousPetId = userData[0]?.selected_pet_id;

			// Fetch the current vote count for the newly selected pet
			const { data: newPetData, error: newPetError } = await supabase
				.from('pets')
				.select('votes')
				.eq('id', petId);

			if (newPetError) {
				console.error('Error fetching new pet data:', newPetError);
				return;
			}

			// Decrement the vote count for the previous pet
			if (previousPetId) {
				const { data: prevPetData } = await supabase
					.from('pets')
					.select('votes')
					.eq('id', previousPetId);

				const prevVotes = Math.max(prevPetData[0].votes - 1, 0);

				await supabase
					.from('pets')
					.update({ votes: prevVotes })
					.eq('id', previousPetId);

				// Update the vote count for the previous pet in filteredPets
				const prevPetIndex = filteredPets.findIndex(
					(pet) => pet.id === previousPetId
				);
				if (prevPetIndex !== -1) {
					setFilteredPets((prevFilteredPets) => {
						const updatedPets = [...prevFilteredPets];
						updatedPets[prevPetIndex].votes = prevVotes;
						return updatedPets;
					});
				}
			}

			// Increment the vote count for the newly selected pet
			const newVotes = newPetData[0].votes + 1;
			await supabase.from('pets').update({ votes: newVotes }).eq('id', petId);

			// Update the selected pet ID in the user's profile
			await supabase
				.from('profiles')
				.update({ selected_pet_id: petId })
				.eq('id', userId);

			localStorage.setItem('selectedPetId', petId);

			// Update the vote count for the newly selected pet in filteredPets
			const newPetIndex = filteredPets.findIndex((pet) => pet.id === petId);
			if (newPetIndex !== -1) {
				setFilteredPets((prevFilteredPets) => {
					const updatedPets = [...prevFilteredPets];
					updatedPets[newPetIndex].votes = newVotes;
					return updatedPets;
				});
			}

			// Fetch the updated list of all pets
			const { data: updatedPetsData, error: updatedPetsError } = await supabase
				.from('pets')
				.select('id, pet_name, owner_name, image_url, votes, status')
				.eq('status', true)
				.order('votes', { ascending: false });

			if (updatedPetsError) {
				console.error('Error fetching updated pet data:', updatedPetsError);
				return;
			}

			// Update the state with the new list of all pets and selected pet ID
			setPets(updatedPetsData);
			setSelectedPetId(petId);

			setLoadingState((prevLoadingState) => ({
				...prevLoadingState,
				[petId]: false,
			}));
		} catch (error) {
			console.error('Error handling vote:', error);
		}
	};

	const login = () => {
		navigate('/magiclink');
	};

	const handleSubmit = () => {
		navigate('/votesuccess');
	};

	useEffect(() => {
		if (user && user.id) {
			fetchUserPet(user.id);
		}
		fetchPetData();
		window.addEventListener('scroll', checkLazyLoad);
		const storedPetId = localStorage.getItem('selectedPetId');
		if (storedPetId) {
			setSelectedPetId(storedPetId);
		}
		return () => {
			window.removeEventListener('scroll', checkLazyLoad);
		};
	}, [selectedPetId]);

	const handleSearchInputChange = (event) => {
		setSearchQuery(event.target.value);
	};

	const handleSearchButtonClick = () => {
		if (searchQuery.trim() === '') {
			return;
		}
		fetchPetsBySearchQuery(searchQuery);
	};

	const viewSinglePet = (petId) => {
		navigate(`/single/${petId}`);
	};

	const allPets = () => {
		setSearchTriggered(false);
	};

	return (
		<div ref={containerRef}>
			<img
				src={hero}
				alt='hero pic'
				className=' object-scale h-auto w-full'
			></img>
			<div className='flex justify-center items-center flex-col gap-4 p-4 text-center'>
				<div>
					<h1 className='text-2xl'>Thank you for voting!</h1>
					<p className='text-lg m-8 font-bold'>{welcomeMessage}</p>
					{/* {!user && (
						<button
							className='py-4 px-10 bg-[#184076] hover:bg-[#CC7F29] rounded text-white'
							onClick={login}
						>
							Vote Now
						</button>
					)} */}
				</div>
				{/* {user && (
					<div className='w-full flex justify-center m-4'>
						<button
							className='py-4 px-10 text-white bg-[#CC7F29]  hover:bg-[#184076] font-bold rounded'
							onClick={handleSubmit}
						>
							Submit
						</button>
					</div>
				)}
				<input
					type='text'
					placeholder='Search pets...'
					value={searchQuery}
					onChange={handleSearchInputChange}
					className='p-2 border rounded my-4 w-full md:w-1/2 block m-auto'
				/>

				<button
					className='py-2 px-4 bg-[#CC7F29] text-white rounded cursor-pointer hover:bg-[#184076]'
					onClick={handleSearchButtonClick}
				>
					Search
				</button>
				{searchTriggered && (
					<button
						className='py-2 px-4 mx-2 bg-[#184076] text-white rounded cursor-pointer hover:bg-[#CC7F29]'
						onClick={allPets}
					>
						All Pets
					</button>
				)} */}
				<div className='container mx-auto'>
					<div className='grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
						{searchTriggered ? (
							filteredPets.length > 0 ? (
								filteredPets.slice(0, lazyLoadIndex).map((pet, index) => (
									<div
										key={pet.id}
										className={`relative rounded lg:p-4 overflow-hidden shadow-md ${
											pet.id === selectedPetId ? 'bg-green-100' : 'bg-white '
										}`}
									>
										<img
											src={pet.image_url}
											alt={pet.pet_name}
											className='h-[12rem] md:h-[18rem] lg:[22rem] w-full object-cover mb-2 md:mb-4 rounded cursor-pointer'
											onClick={() => viewSinglePet(pet.id)}
										/>
										<div>
											<div
												className='group flex justify-center items-center cursor-pointer'
												onClick={() => viewSinglePet(pet.id)}
											>
												<h3 className='text-lg font-semibold mb-1 text-center'>
													{pet.pet_name}
												</h3>
												<img
													src={arrow}
													className='w-[20px] group-hover:translate-x-1 transition-transform duration-2000  ease-in-out'
												/>
											</div>

											{user && (
												<button
													className={`py-2 px-4 rounded mt-2 w-full ${
														loadingState[pet?.id]
															? 'bg-gray-500 text-white cursor-not-allowed'
															: pet?.id === selectedPetId
															? 'bg-green-500 text-white cursor-not-allowed'
															: 'bg-[#184076] text-white hover:bg-[#CC7F29]'
													}`}
													onClick={() => {
														if (
															pet?.id !== selectedPetId &&
															!loadingState[pet?.id]
														) {
															handleVote(pet?.id, user.id);
														}
													}}
													disabled={
														pet?.id === selectedPetId || loadingState[pet?.id]
													}
												>
													{loadingState[pet?.id]
														? 'Voting...'
														: pet?.id === selectedPetId
														? 'Voted'
														: 'Vote'}
												</button>
											)}
											<p className='text-sm text-gray-600 mb-4 text-center'>{`Votes: ${pet.votes}`}</p>
										</div>
									</div>
								))
							) : (
								<p>No search results found.</p>
							)
						) : (
							currentPets.slice(0, lazyLoadIndex).map((pet, index) => (
								<div
									key={pet?.id}
									className='border rounded lg:p-4 shadow-md
									'
								>
									<img
										src={pet.image_url}
										alt={pet.pet_name}
										className=' h-[12rem] md:h-[18rem] lg:h-[22rem] w-full object-cover mb-2 md:mb-4 rounded cursor-pointer'
										onClick={() => viewSinglePet(pet.id)}
									/>
									{index === 0 && currPage === 1 && (
										<div className='flex justify-center '>
											<img
												src={fire}
												alt='fire gif'
												className='w-8 '
											/>
											<img
												src={fire}
												alt='fire gif'
												className='w-8 '
											/>
											<img
												src={fire}
												alt='fire gif'
												className='w-8 '
											/>
										</div>
									)}
									{index === 1 && currPage === 1 && (
										<div className='flex justify-center'>
											<img
												src={fire}
												alt='fire gif'
												className='w-8 '
											/>
											<img
												src={fire}
												alt='fire gif'
												className='w-8 '
											/>
										</div>
									)}
									{index === 2 && currPage === 1 && (
										<img
											src={fire}
											alt='fire gif'
											className='w-8 mx-auto'
										/>
									)}

									<div
										className='group flex justify-center items-center cursor-pointer  '
										onClick={() => viewSinglePet(pet.id)}
									>
										<h3 className='text-lg font-semibold mb-1 text-center '>
											{pet.pet_name}
										</h3>
										<img
											src={arrow}
											className='w-[20px] group-hover:translate-x-1 transition-transform duration-2000  ease-in-out'
										/>
									</div>
									<p className='text-sm text-gray-600 mb-4 text-center px-4'>
										{user && (
											<button
												className={`py-2 px-4 rounded mt-2 w-full ${
													loadingState[pet?.id]
														? 'bg-gray-500 text-white cursor-not-allowed'
														: pet?.id === selectedPetId
														? 'bg-green-500 text-white cursor-not-allowed'
														: 'bg-[#184076] text-white hover:bg-[#CC7F29]'
												}`}
												onClick={() => {
													if (
														pet?.id !== selectedPetId &&
														!loadingState[pet?.id]
													) {
														handleVote(pet?.id, user?.id);
													}
												}}
												disabled={
													pet?.id === selectedPetId || loadingState[pet?.id]
												}
											>
												{loadingState[pet?.id]
													? 'Voting...'
													: pet?.id === selectedPetId
													? 'Voted'
													: 'Vote'}
											</button>
										)}
										Votes: {pet.votes}
									</p>
								</div>
							))
						)}
					</div>
					{!searchTriggered && (
						<Pagination
							totalPosts={pets.length}
							postsPerPage={postsPerPage}
							setCurrentPage={setCurrPage}
							currentPage={currPage}
						/>
					)}
				</div>
				<p className='flex justify-center p-2'>
					<a
						href='https://williammattar.com/terms-and-conditions-pet-photo-competition-2023/'
						target='_blank'
					>
						<span className='text-orange-500'>Click here </span>
					</a>
					&nbsp;for full terms and conditions .
				</p>
			</div>
		</div>
	);
};
export default Vote;
