import React, { useState, useEffect, useRef } from 'react';
import supabase from '../supabase';
import { useNavigate } from 'react-router-dom';
import hero from '../assets/hero.png';
import Pagination from './Pagination';
import fire from '../assets/icons8-fire.gif';
import arrow from '../assets/arrow.svg';

const ViewPets = ({ user }) => {
	const [pets, setPets] = useState([]);
	const [lazyLoadIndex, setLazyLoadIndex] = useState(6); // Number of images to load initially
	const [searchQuery, setSearchQuery] = useState('');
	const [searchTriggered, setSearchTriggered] = useState(false);
	const [currPage, setCurrPage] = useState(1);
	const [postsPerPage, setPostsPerPage] = useState(16);
	const [filteredPets, setFilteredPets] = useState([]);
	const navigate = useNavigate();

	const lastPostIndex = currPage * postsPerPage;
	const firstPostIndex = lastPostIndex - postsPerPage;
	const currentPets = pets.slice(firstPostIndex, lastPostIndex);

	const welcomeMessage = user
		? `Welcome ${
				user.user_metadata?.firstName.charAt(0).toUpperCase() +
				user.user_metadata?.firstName.slice(1)
		  }`
		: '';

	const fetchPetData = async () => {
		try {
			const { data: petsData, error } = await supabase
				.from('pets')
				.select('id, pet_name,image_url, votes, status')
				.eq('status', true)
				.order('votes', { ascending: false });

			if (error) {
				console.error('Error fetching pet data:', error);
			} else {
				setPets(petsData);
			}
		} catch (error) {
			console.error('Error fetching pet data:', error);
		}
	};

	const fetchPetsBySearchQuery = async (query) => {
		try {
			const { data, error } = await supabase
				.from('pets')
				.select('id, pet_name, image_url, votes, status')
				.eq('status', true)
				.order('votes', { ascending: false })
				.then((response) => response);

			if (error) {
				console.error('Error fetching pet data:', error);
			} else {
				// Convert both the search query and pet names to lowercase for case-insensitive search
				const lowerCaseQuery = query.toLowerCase();
				const filteredPets = data.filter((pet) =>
					pet.pet_name.toLowerCase().includes(lowerCaseQuery)
				);
				setFilteredPets(filteredPets);
				setSearchTriggered(true);
				console.log('searched pet', filteredPets);
			}
		} catch (error) {
			console.error('Error fetching pet data:', error);
		} finally {
			setSearchQuery('');
		}
	};

	const containerRef = useRef(null);

	const checkLazyLoad = () => {
		const container = containerRef.current;
		if (container) {
			const containerRect = container.getBoundingClientRect();
			if (containerRect.bottom < window.innerHeight * 2) {
				setLazyLoadIndex((prevIndex) => prevIndex + 6); // Load more images when container is within 2x viewport height
			}
		}
	};

	useEffect(() => {
		fetchPetData();
		window.addEventListener('scroll', checkLazyLoad);
		return () => {
			window.removeEventListener('scroll', checkLazyLoad);
		};
	}, []);

	useEffect(() => {
		fetchPetData();
		// Add cleanup logic if necessary
	}, []); // Empty dependency array ensures it runs only once
	// Handle changes in searchQuery and filteredPets

	const handleLoginClick = () => {
		navigate('/magiclink');
	};

	const clickHandler = () => {
		navigate('/vote');
	};

	const handleSearchInputChange = (event) => {
		setSearchQuery(event.target.value);
	};

	const handleSearchButtonClick = () => {
		if (searchQuery.trim() === '') {
			return;
		}
		fetchPetsBySearchQuery(searchQuery);
	};

	const viewSinglePet = (petId) => {
		navigate(`/single/${petId}`);
	};

	const allPets = () => {
		setSearchTriggered(false);
	};

	return (
		<div ref={containerRef}>
			<img
				src={hero}
				alt='hero pic'
				className=' object-scale h-auto w-full'
			></img>
			<div className='container mx-auto my-8 px-[1rem] bg-cover bg-center'>
				<h1 className='text-4xl mb-4 text-center font-bolder'>
					Thank you for voting!
				</h1>
				{!user && (
					<div className='text-center mb-8'>
						<p className='mb-4 text-lg font-bold'>
							Voting has ended. The winner of the 2023 William Mattar Pet Photo
							Contest will be announced on November 30th!
						</p>
						{/* <button
							className='py-4 px-8 md:w-1/4 bg-[#CC7F29] text-white rounded cursor-pointer hover:bg-[#184076]'
							onClick={handleLoginClick}
						>
							Vote Now
						</button>

						<input
							type='text'
							placeholder='Search pets...'
							value={searchQuery}
							onChange={handleSearchInputChange}
							className='p-2 border rounded my-4 w-full md:w-1/2 block m-auto'
						/>
						{searchTriggered && (
							<button
								className='py-2 px-4 mx-2 bg-[#184076] text-white rounded cursor-pointer hover:bg-[#CC7F29]'
								onClick={allPets}
							>
								All Pets
							</button>
						)}
						<button
							className='py-2 px-4 bg-[#CC7F29] text-white rounded cursor-pointer hover:bg-[#184076]'
							onClick={handleSearchButtonClick}
						>
							Search
						</button> */}
					</div>
				)}
				{user && (
					<div className='text-center mb-8'>
						<p className='mb-4 text-2xl'>{`${welcomeMessage}`}</p>
						<p className='mb-4 text-lg font-bold'>
							<br></br>
							Voting has ended. The winner of the 2023 William Mattar Pet Photo
							Contest will be announced on November 30th!{' '}
						</p>
						{/* <button
							className='py-4 px-8 bg-[#CC7F29] text-white rounded cursor-pointer hover:bg-[#184076] font-bold mb-2'
							onClick={clickHandler}
						>
							Vote Now
						</button>
						<input
							type='text'
							placeholder='Search pets...'
							value={searchQuery}
							onChange={handleSearchInputChange}
							className='p-2 border rounded mb-4 w-full'
						/>
						{searchTriggered && (
							<button
								className='py-2 px-4 mx-2 bg-[#184076] text-white rounded cursor-pointer hover:bg-[#CC7F29]'
								onClick={allPets}
							>
								All Pets
							</button>
						)}
						<button
							className='py-2 px-4 bg-[#CC7F29] text-white rounded cursor-pointer hover:bg-[#184076]'
							onClick={handleSearchButtonClick}
						>
							Search
						</button> */}
					</div>
				)}
				<div className='grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
					{searchTriggered ? (
						filteredPets.length > 0 ? (
							filteredPets.slice(0, lazyLoadIndex).map((pet, index) => (
								<div
									key={pet.id}
									className='border rounded lg:p-4 shadow-md  '
								>
									<img
										src={pet.image_url}
										alt={pet.pet_name}
										className=' h-[12rem] md:h-[18rem] lg:h-[22rem] w-full object-cover mb-2 md:mb-4 rounded cursor-pointer'
										onClick={() => viewSinglePet(pet.id)}
									/>
									<div
										className='group flex justify-center items-center cursor-pointer  '
										onClick={() => viewSinglePet(pet.id)}
									>
										<h3 className='text-lg font-semibold mb-1 text-center '>
											{pet.pet_name}
										</h3>
										<img
											src={arrow}
											className='w-[20px] group-hover:translate-x-1 transition-transform duration-2000  ease-in-out'
										/>
									</div>

									<p className='text-sm text-gray-600 mb-4 text-center'>
										Votes: {pet.votes}
									</p>
								</div>
							))
						) : (
							<p>No search results found.</p>
						)
					) : (
						currentPets.slice(0, lazyLoadIndex).map((pet, index) => (
							<div
								key={pet.id}
								className='border rounded lg:p-4 shadow-md '
							>
								<img
									src={pet.image_url}
									alt={pet.pet_name}
									className=' h-[12rem] md:h-[18rem] lg:h-[22rem] w-full object-cover mb-2 md:mb-4 rounded cursor-pointer'
									onClick={() => viewSinglePet(pet.id)}
								/>
								{index === 0 && currPage === 1 && (
									<div className='flex justify-center '>
										<img
											src={fire}
											alt='fire gif'
											className='w-8 '
										/>
										<img
											src={fire}
											alt='fire gif'
											className='w-8 '
										/>
										<img
											src={fire}
											alt='fire gif'
											className='w-8 '
										/>
									</div>
								)}
								{index === 1 && currPage === 1 && (
									<div className='flex justify-center'>
										<img
											src={fire}
											alt='fire gif'
											className='w-8 '
										/>
										<img
											src={fire}
											alt='fire gif'
											className='w-8 '
										/>
									</div>
								)}
								{index === 2 && currPage === 1 && (
									<img
										src={fire}
										alt='fire gif'
										className='w-8 mx-auto'
									/>
								)}
								<div
									className='group flex justify-center items-center cursor-pointer'
									onClick={() => viewSinglePet(pet.id)}
								>
									<h3 className='text-lg font-semibold mb-1 text-center '>
										{pet.pet_name}
									</h3>
									<img
										src={arrow}
										className='w-[20px] group-hover:translate-x-1 transition-transform duration-2000  ease-in-out'
									/>
								</div>

								<p className='text-sm text-gray-600 mb-4 text-center '>
									Votes: {pet.votes}
								</p>
							</div>
						))
					)}
				</div>
				{!searchTriggered && (
					<Pagination
						totalPosts={pets.length}
						postsPerPage={postsPerPage}
						setCurrentPage={setCurrPage}
						currentPage={currPage}
					/>
				)}
			</div>
			<p className='flex justify-center p-2'>
				<a
					href='https://williammattar.com/terms-and-conditions-pet-photo-competition-2023/'
					target='_blank'
				>
					<span className='text-orange-500'>Click here </span>
				</a>
				&nbsp;for full terms and conditions .
			</p>
		</div>
	);
};

export default ViewPets;
