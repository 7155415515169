import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import wmLogo from '../assets/mattarLogo.svg';
const MagicLink = ({ supabase, user }) => {
	const [email, setEmail] = useState('');
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [county, setCounty] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');
	const [isAgreed, setIsAgreed] = useState(true);
	const [isSignUp, setIsSignUp] = useState(true); // Track if the user is in sign-up mode
	const [communication, setCommunication] = useState(true);

	const navigate = useNavigate();

	// useEffect(() => {
	// 	if (user) {
	// 		navigate('/');
	// 	}
	// }, [user, navigate]);

	const handleMagicLogin = async () => {
		setLoading(true);
		setError('');

		if (!email || email.indexOf('@') === -1) {
			setError('Invalid email format.');
			setLoading(false);
			return;
		}

		try {
			// Check if the email exists in your database
			const { data, error: emailError } = await supabase
				.from('profiles') // Replace 'profiles' with your table name
				.select('id')
				.eq('email', email)
				.single();

			if (emailError || !data) {
				setError(
					'Email not found. Please sign up or check your email to confirm.'
				);
				setLoading(false);
				return;
			}

			// Send magic link to the user's email
			const { error: magicLinkError } = await supabase.auth.signInWithOtp({
				email: email,
				options: {
					emailRedirectTo: 'petcontest.williammattar.com/vote',
				},
			});

			if (magicLinkError) {
				console.log(magicLinkError);
				setError('Failed to send magic link. Please try again later.');
			} else {
				alert('Magic link sent! Please check your email to sign in.');
			}
		} catch (error) {
			console.error('Magic Link Error:', error);
			setError('An unexpected error occurred. Please try again later.');
		} finally {
			setLoading(false);
		}
	};

	const handleMagicSignUp = async () => {
		console.log('logging in');
		setLoading(true);
		setError('');
		const randomPassword = Math.random().toString(36).substring(2, 8);

		if (!email || !firstName || !lastName || !phoneNumber || !county) {
			setError('Please fill out all fields.');
			setLoading(false);
			return;
		}

		if (email.indexOf('@') === -1) {
			setError('Invalid email format.');
			setLoading(false);
			return;
		}

		try {
			// Step 2: Create a new user without password
			const {
				data: { user },
				error,
			} = await supabase.auth.signUp({
				email,
				password: randomPassword,
				options: {
					data: {
						firstName,
						lastName,
						phoneNumber,
						email,
						county,
						checkbox: isAgreed,
						communication,
					},
				},
			});

			if (error) {
				console.error('Error creating user:', error);
				if (error.message.includes('already'))
					setError(
						'Existing account detected, please use the magic link login to sign in'
					);
				setLoading(false);
				return;
			}

			// Step 3: Create a profile for the user in the 'profiles' table
			const { data: profileData, profileError } = await supabase
				.from('profiles')
				.upsert([
					{
						id: user.id, // Use the user's ID as the primary key
						firstname: firstName,
						lastname: lastName,
						phonenumber: phoneNumber,
						email: email,
						county: county,
						checkbox: isAgreed,
						communication,
					},
				]);

			console.log('profile', profileData);

			if (profileError) {
				console.error('Error creating user profile:', profileError);
				setError('Failed to create user profile. Please try again later.');
				setLoading(false);
				return;
			}

			// Step 4: Send magic link to the user's email
			const { data, magicLinkError } = await supabase.auth.signInWithOtp({
				email: email,
				options: {
					emailRedirectTo: 'petcontest.williammattar.com/vote',
				},
			});

			if (magicLinkError) {
				console.error('Error sending magic link:', magicLinkError);
				setError('Failed to send magic link. Please try again later.');
				setLoading(false);
				return;
			}
			alert('Magic link sent! Please check your email to sign in.');
		} catch (error) {
			console.error('Magic Link Error:', error);
			setError(error.message);
		} finally {
			setLoading(false);
			navigate('/signupconfirmed');
		}
	};
	return (
		<div className='flex flex-col items-center justify-center h-screen p-4'>
			<img
				src={wmLogo}
				alt='william mattar logo'
				className='w-[300px] mt-8'
			/>
			<div className='container flex flex-col items-center mt-8 max-w-full'>
				{!isSignUp && (
					<div className='mb-4 w-full max-w-sm flex flex-col gap-4'>
						<p>Please enter your email to login.</p>
						{error && (
							<p className='text-red-500 w-full text-start max-w-sm'>{error}</p>
						)}
						<input
							type='email'
							placeholder='Enter your email'
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							required
							className='border-2 border-slate-500 w-full px-2 py-3'
						/>

						<button
							className='px-4 py-3 bg-blue-500 text-white font-bold rounded hover:bg-blue-700 w-full max-w-sm'
							onClick={handleMagicLogin}
							disabled={loading}
						>
							{loading ? 'Logging in...' : 'Login'}
						</button>

						<p>
							Don't have an account?{' '}
							<span
								className='text-blue-600 cursor-pointer'
								onClick={() => setIsSignUp(true)}
							>
								Sign Up
							</span>
						</p>
					</div>
				)}

				{isSignUp && (
					<>
						<div className='mb-4 w-full max-w-sm'>
							<input
								type='text'
								placeholder='First Name'
								value={firstName}
								onChange={(e) => setFirstName(e.target.value)}
								className='border-2 border-slate-500 w-full px-2 py-3'
								required
							/>
						</div>
						<div className='mb-4 w-full max-w-sm'>
							<input
								type='text'
								placeholder='Last Name'
								value={lastName}
								onChange={(e) => setLastName(e.target.value)}
								className='border-2 border-slate-500 w-full px-2 py-3'
								required
							/>
						</div>
						<div className='mb-4 w-full max-w-sm'>
							<input
								type='tel'
								placeholder='Phone Number'
								value={phoneNumber}
								onChange={(e) => setPhoneNumber(e.target.value)}
								className='border-2 border-slate-500 w-full px-2 py-3'
								required
							/>
						</div>
						<div className='mb-4 w-full max-w-sm'>
							<input
								type='email'
								placeholder='Email Address'
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								required
								className='border-2 border-slate-500 w-full px-2 py-3'
							/>
						</div>
						<div className='mb-4 w-full max-w-sm'>
							<input
								type='text'
								placeholder='County'
								value={county}
								onChange={(e) => setCounty(e.target.value)}
								required
								className='border-2 border-slate-500 w-full px-2 py-3'
							/>
						</div>
						<div className='mb-4 w-full max-w-sm flex items-center'>
							<input
								type='checkbox'
								checked={isAgreed}
								onChange={() => setIsAgreed(!isAgreed)}
								className='h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded'
							/>
							<label
								htmlFor='agreement'
								className='text-gray-700 ml-2'
							>
								I agree to the{' '}
								<a
									href='https://williammattar.com/terms-and-conditions-pet-photo-competition-2023/'
									target='_blank'
									rel='noreferrer'
									className='text-blue-500'
								>
									terms and conditions
								</a>
							</label>
						</div>
						<div className='mb-4 w-full max-w-sm '>
							<input
								type='checkbox'
								checked={communication}
								onChange={() => setCommunication(!communication)}
								className='h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded'
							/>
							<label
								htmlFor='agree for communication'
								className='text-gray-700 ml-2'
							>
								I would like to receive promotional, advertising, and/or
								marketing materials from William Mattar, P.C. in the future,
								including emails and text messages/SMS
							</label>
						</div>

						{error && (
							<p className='text-red-500 w-full text-center max-w-sm'>
								{error}
							</p>
						)}
						<button
							className='px-4 py-3 bg-blue-500 text-white font-bold rounded hover:bg-blue-700 w-full max-w-sm'
							onClick={handleMagicSignUp}
							disabled={loading}
						>
							{loading ? 'Signing Up...' : 'Sign Up'}
						</button>
						<p onClick={() => setIsSignUp(false)}>
							Already have an account?
							<span className='text-blue-600 cursor-pointer'>
								{' '}
								Sign in here
							</span>
						</p>
					</>
				)}
			</div>
		</div>
	);
};

export default MagicLink;
