import React, { useState, useEffect } from 'react';
import supabase from '../supabase';
import { useNavigate } from 'react-router-dom';
import hero from '../assets/hero.png';
import { useParams } from 'react-router-dom';

const ViewSinglePet = ({ user }) => {
	const [pet, setPet] = useState([]);
	const navigate = useNavigate();
	const { petId } = useParams();
	const [selectedPetId, setSelectedPetId] = useState(
		petId || localStorage.getItem('selectedPetId') || null
	);
	const [loadingState, setLoadingState] = useState({});

	const fetchSinglePetData = async (petId) => {
		try {
			const { data: petData, error } = await supabase
				.from('pets')
				.select('id, pet_name, owner_name, image_url, votes, status')
				.eq('id', petId);

			if (error) {
				console.error('Error fetching pet data:', error);
				return [];
			} else {
				setPet(petData);
			}
		} catch (error) {
			console.error('Error fetching pet data:', error);
		}
	};

	const fetchUserPet = async (userId) => {
		try {
			const { data, error } = await supabase
				.from('profiles')
				.select('selected_pet_id')
				.eq('id', userId);
			if (error) {
				console.error('Error fetching user data:', error);
				return;
			}
			const selectedPetId = data[0]?.selected_pet_id;
			setSelectedPetId(selectedPetId);
		} catch (error) {
			console.error('Error fetching user pet data:', error);
		}
	};

	const handleVote = async (petId, userId) => {
		setLoadingState((prevLoadingState) => ({
			...prevLoadingState,
			[petId]: true,
		}));

		try {
			// Fetch the previously selected pet ID from the user's profile
			const { data: userData, error: userError } = await supabase
				.from('profiles')
				.select('selected_pet_id')
				.eq('id', userId);
			if (userError) {
				console.error('Error fetching user data:', userError);
				return;
			}

			const previousPetId = userData[0]?.selected_pet_id;

			// Fetch the current vote count for the newly selected pet
			console.log('Fetching new pet data for petId:', petId);
			const { data: newPetData, error: newPetError } = await supabase
				.from('pets')
				.select('votes')
				.eq('id', petId);
			if (newPetError) {
				console.error('Error fetching new pet data:', newPetError);
				console.error('Full error object:', newPetError);
				return;
			}
			// Decrement the vote count for the previous pet
			if (previousPetId) {
				const { data: prevPetData } = await supabase
					.from('pets')
					.select('votes')
					.eq('id', previousPetId);

				const prevVotes = Math.max(prevPetData[0].votes - 1, 0);

				await supabase
					.from('pets')
					.update({ votes: prevVotes })
					.eq('id', previousPetId);
			}
			// Increment the vote count for the newly selected pet
			const newVotes = newPetData[0].votes + 1;
			await supabase.from('pets').update({ votes: newVotes }).eq('id', petId);

			// Update the selected pet ID in the user's profile
			await supabase
				.from('profiles')
				.update({ selected_pet_id: petId })
				.eq('id', userId);

			const updatedPetData = await fetchSinglePetData(petId);
			setPet(updatedPetData);
			setSelectedPetId(petId);
			localStorage.setItem('selectedPetId', petId);

			setLoadingState((prevLoadingState) => ({
				...prevLoadingState,
				[petId]: false,
			}));
		} catch (error) {
			console.error('Error handling vote:', error);
		}
	};
	const fetchData = async () => {
		const localStoragePetId = localStorage.getItem('selectedPetId');
		if (localStoragePetId) {
			setSelectedPetId(localStoragePetId);
		} else if (user && user.id) {
			fetchUserPet(user.id);
		}
		if (petId) {
			await fetchSinglePetData(petId);
		} else {
			console.log('no pet found');
		}
	};

	useEffect(() => {
		fetchData();
	}, [petId, user, selectedPetId]);

	const login = () => {
		navigate('/magiclink');
	};

	const copyLink = () => {
		navigator.clipboard.writeText(window.location.href).then(() => {
			alert('Link copied to clipboard!');
		});
	};

	const allPets = () => {
		navigate('/vote');
	};
	return (
		<div className='mb-12'>
			<img
				src={hero}
				alt='hero pic'
				className='md:hidden'
			></img>

			{pet?.length > 0 && (
				<div className='p-4'>
					<div className='flex items-center justify-evenly m-4'>
						<button
							className='py-4 px-8 bg-[#184076] hover:bg-[#CC7F29] rounded text-white font-bold'
							onClick={allPets}
						>
							All Pets
						</button>

						<button
							className='py-4 px-10 bg-[#184076] hover:bg-[#CC7F29] rounded text-white font-bold'
							onClick={copyLink}
						>
							Share Me
						</button>
					</div>

					<div className='border border-blue-100 rounded-lg p-4 w-full md:w-1/3 md:m-auto flex flex-col items-center'>
						<img
							src={pet[0].image_url}
							alt={pet[0].pet_name}
							className='w-full h-full object-cover mb-2 md:mb-4 rounded  md:h-[20rem] lg:h-[30rem]'
						/>
						<p className='text-center text-xl md:text-3xl'>{pet[0].pet_name}</p>
						<p className='text-sm text-gray-600 mb-4 text-center md:text-lg'>
							Votes: {pet[0].votes}
						</p>
						{!user && (
							<button
								className='py-4 px-10 bg-[#184076] hover:bg-[#CC7F29]  rounded text-white font-bold'
								onClick={login}
							>
								Vote Now
							</button>
						)}
						{user && (
							<button
								className={`py-2 px-4 rounded mt-2 w-full ${
									loadingState[pet[0]?.id]
										? 'bg-gray-500 text-white cursor-not-allowed'
										: pet[0]?.id === selectedPetId
										? 'bg-green-500 text-white cursor-not-allowed'
										: 'bg-[#184076] text-white hover:bg-[#CC7F29]'
								}`}
								onClick={() => {
									if (
										pet[0]?.id !== selectedPetId &&
										!loadingState[pet[0]?.id]
									) {
										handleVote(pet[0]?.id, user.id);
									}
								}}
								disabled={
									pet[0]?.id === selectedPetId || loadingState[pet[0]?.id]
								}
							>
								{loadingState[pet[0]?.id]
									? 'Voting...'
									: pet[0]?.id === selectedPetId
									? 'Voted'
									: 'Vote'}
							</button>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default ViewSinglePet;
