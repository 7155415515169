import React from 'react';
import { useNavigate } from 'react-router-dom';

const VoteSuccess = ({ user }) => {
	const firstName =
		user?.user_metadata.firstName.charAt(0).toUpperCase() +
		user?.user_metadata.firstName.slice(1);
	const navigate = useNavigate();
	return (
		<div className='flex justify-center items-center h-screen bg-gray-100'>
			<div className='text-center flex flex-col gap-4 items-center'>
				<h1 className='text-4xl font-bold mb-4'>Thank You {firstName}!</h1>
				<p className='text-lg'>
					We have recieved your vote! Stayed tuned for the announcement of the
					winner!
				</p>

				{/* <button
					className='mt-4 p-4 bg-blue-500 text-white rounded w-[350px] font-bold my-4 hover:bg-blue-400'
					onClick={() => navigate('vote.williammattar.com/')}
				>
					View Contestants
				</button> */}
				<a
					href='https://williammattar.com'
					target='_blank'
					rel='noopener noreferrer'
				>
					<p className='underline'>
						Visit our site for more upcoming campaigns and events.
					</p>
					<button className='mt-4 p-4 bg-green-500 text-white rounded w-[350px] font-bold my-4 hover:bg-green-400'>
						Home
					</button>
				</a>
			</div>
		</div>
	);
};

export default VoteSuccess;
